.center-card {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 248, 238, 1);
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 30%;
height: 50%;
display: flex;
flex-direction: column;
justify-content: center;
/* align-items: center; */
    text-align: center;
   
  }
  .log-main{}
  
  .center-card h2 {
    margin-bottom: 20px;
    font-family: "Montserrat", sans-serif;
    font-size: 30px;
    font-weight: 500;
  }
  
  .center-card .name-inps {
    display: flex;
    flex-direction: column;
  }
  
  .center-card input {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
   
  }
  
  .center-card button {
    padding: 10px;
    background-color: #FFCF06;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 700;
    font-size: 15px;
    font-family: "Inter", sans-serif;
  }

  
  @media(max-width:1000px){
    .center-card {
      width: 50%;
    }
  }
  @media(max-width:768px){
    .center-card {
      width: 80%;
    }
  }