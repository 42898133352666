/* ButtonLoader.css */
.button-loader {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
}

.button-loader .spinner {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 25px;
    height: 25px;
    margin: 2px;
    border: 4px solid rgb(255, 6, 6);
    border-radius: 50%;
    animation: spin 1.2s linear infinite;
    border-color: rgb(255, 6, 214) transparent transparent transparent;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
