/* SmallScreenLoader.css */
.small-screen-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Optional: Adds a semi-transparent background */
    z-index: 9999; /* Ensure it is on top of other content */
}

.small-screen-loader .spinner {
    box-sizing: border-box;
    display: block;
    width: 80px; /* Increased width */
    height: 80px; /* Increased height */
    border: 8px solid rgba(255, 207, 6, 1); /* Increased border thickness */
    border-radius: 50%;
    animation: spin 1.2s linear infinite;
    border-color: rgba(255, 207, 6, 1) transparent transparent transparent;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
