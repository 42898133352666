*{
  padding: 0;margin: 0;box-sizing: border-box;
}
nav{
  height: 80px;
  background-color: rgba(255, 255, 255, 1);
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav ul{
  display: flex;
  flex-direction: row;
  align-items: center;
gap: 50px;

}
nav ul i{
  font-size: 20px;
  color: black;
}
nav ul li{
  list-style: none;
  font-family: "Outfit", sans-serif;
  font-size: 15px;
  font-weight: 400;

}
.banner{
  background-image: url(./Assets/Slider_1\ 1.png);
  padding-top: 200px;
  padding-bottom: 200px;
  padding-left: 50px;
  background-size: cover;
height: 100vh;
}
.banner button{
  width: 178px;
  height: 60px;
  background-color: rgba(255, 207, 6, 1);
  border-radius: 10px;
  color: white;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 700;
  border: none;
}
.banner h1{
  font-family: "Inter", sans-serif;


  font-size: 61px;
  font-weight: 400;
}
.Terms h1 span , .check-heading span ,.plans h1 span , .our-product-div h1 span, .Blog-div h1 span , .Testimonials h1 span , .fruitpunch h1 span , .banner h1 span , .second-banner-left h1 span , .ProductCategories h1 span , .products-div h1 span{
  color: #EF4136;
}
.check-heading-p{
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: rgba(61, 61, 61, 1);
  
  text-align: center;
}

.banner p{
  font-family: "Outfit", sans-serif;

  font-size: 16px;
  font-weight: 400;
}
.second-banner{
  display: flex;
  flex-direction: row;
  padding: 30px;
}
.second-banner-right{
width: 50%;
display: flex;
justify-content: center;
align-items: center;
}
.second-banner-right img{
  width: 80%;
}
.second-banner-left{
  width: 50%;
  padding: 50px;

}
.second-banner-left h1{
  font-family: "Outfit", sans-serif;

  font-size: 51px;
  font-weight: 400;
}
.second-banner-left p{
  font-family: "Inter", sans-serif;


  font-size: 16px;
  font-weight: 400;
  color: rgba(61, 61, 61, 1);
}
.calories-count{

border-left: 5px solid rgba(15, 121, 70, 1);
padding-left: 20px;
/* padding-top: 5px;
padding-bottom: 5px; */

}
.calories-count p{
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: rgba(30, 50, 52, 1);
}
.calories-count h2{
  font-family: "Poppins", sans-serif;
  font-size:32px;
  font-weight: 700;
}
.calories-count-div{
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.second-banner-left button{
  width: 191px;
  height: 60px;
  background-color: rgba(255, 207, 6, 1);
  border: none;
  border-radius: 10px;
  font-family: "Inter", sans-serif;


  font-size: 16px;
  font-weight: 700;
  color: white;

}

.Terms h1 , .plans h1 ,.ProductCategories h1 , .products-div h1 , .Testimonials h1 , .Blog-div h1 , .our-product-div h1  {
  font-family: "Outfit", sans-serif;

  font-size: 51px;
  font-weight: 400;
  text-align: center;

}

.prod-cat-cards{
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  gap: 70px;
}
.prod-cat-cards-div{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.prod-cat-card{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.prod-cat-card p{
  font-family: "Outfit", sans-serif;

  font-size: 20px;
  font-weight: 400;
}
.products-div{
  background-color: rgba(255, 248, 238, 1);
  /* padding-top: 50px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-bottom: 50px; */
}
.products-div p{
  font-family: "Outfit", sans-serif;

  font-size: 24px;
  font-weight: 600;
  color: rgba(239, 65, 54, 1);
}
.product-cards{
  width: 100%;
  padding: 50px;
  display: flex ;
  /* justify-content: center; */
  gap: 40px;
  overflow-x: auto !important;
}
.product-card{
  Width:
320px;
Height:
536px;
background-color: white;
border-radius: 10px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
box-shadow: 2px 1px 18px -8px rgba(0,0,0,0.75);
}

.product-card p{
  font-family: "Outfit", sans-serif;

  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}
.product-card h4{
  font-family: "Outfit", sans-serif;

  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;

}
.product-card button{
  width: 163px;
  height: 42px;
  background-color: rgba(255, 207, 6, 1);
  color: white;
  font-family: "Outfit", sans-serif;

  font-size: 16px;
  font-weight: 700;
  border-radius: 10px;
  border: none;
}
.viewallbtn{
  height: 60px;
  width: 165px;
  background-color: rgba(255, 207, 6, 1);
  border-radius: 10px;
  color: white;
  font-family: "Outfit", sans-serif;

  font-size: 16px;
  font-weight: 700;
  
  border: none;

}
.fruitpunch{
  display: flex;
  flex-direction: row;
}
.fruitpunch-left{
  width: 50%;
  padding: 50px;


}
.fruitpunch-left h1{
  text-align: center;
}
.fruitpunch-left img{
  width: 80%;
}
.fruitpunch-right{
  padding: 50px;
  width: 60%;
}
.fruitpunch h1{
  font-family: "Outfit", sans-serif;

  font-size: 51px;
  font-weight: 400;
}
.trial-pack-cards{
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 50px;
  justify-content: center;
  align-items: center;

}
.trial-pack-card{
  width: 50%;
  padding: 20px;
  border-radius: 12px;
  background-color: rgba(254, 240, 239, 1);

  box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Optional: Adds shadow for visual separation */

}
.trial-pack-card h3{
  color: #EF4136;
  font-family: "Poppins", sans-serif !important;
  font-size: 25px;
  font-weight: 800;
}

.trial-pack-card p{
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: rgba(61, 61, 61, 1);
}
.punch-cards{
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.punch-card{
width: 30%;
}
.punch-card h3{
  font-family: "Outfit", sans-serif;

  font-size: 22px;
  font-weight: 600;
color: rgba(30, 50, 52, 1);
margin-top: 15px;
margin-bottom: 10px;
}
.punch-card p{
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: rgba(61, 61, 61, 1);
}
.Testimonials{
  background-color: rgba(255, 248, 238, 1);
  padding: 100px 50px;
}
.Testimonials-card{
  width: 30%;
  background-color: white;
  border-radius: 20px;
  padding: 30px;

}
.Testimonials-card p{
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: rgba(61, 61, 61, 1);
  margin-top: 15px;
}
.test-name p{
  font-family: "Outfit", sans-serif;

  font-size: 18px;
  font-weight: 700;
  color: rgba(30, 50, 52, 1);
}
.test-name h5{
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color:  rgba(30, 50, 52, 1);
}
.review-person{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}
.stars {
  color: gold;
  display: flex;
  gap: 5px;

}
.Testimonials-cards{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.Testimonials-cards {
  display: flex;
  overflow-x: auto;
  gap: 10px; /* Adjust the gap between cards */
  padding: 10px; /* Optional: Adds padding inside the container */
}

.Testimonials-card {
  flex: 0 0 auto;
  width: 300px; /* Adjust the width of each card */
  background-color: #fff; /* Adjust background color */
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Optional: Adds shadow for visual separation */
}
.Blog-div{
  padding: 100px 50px;
}
.blog-card{
  width: 30%;
 
}

.blog-card img{
  width: 100%;
}
.blog-card h2{
  font-family: "Outfit", sans-serif;

  font-size: 22px;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 10px;
}
.blog-card p{
  font-family: "Outfit", sans-serif;

  font-size: 16px;
  font-weight: 400;
  
}
.blog-card-Read{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
}
.blog-cards{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.aboutbanner{
  background-image: url(./Assets/about\ us.png);
  background-size: cover;
  display: flex;
  justify-content: center;
  background-position: center;
  align-items: center;
  padding-top: 200px;
  padding-bottom: 200px;
}
.aboutbanner2{
  background-image: url(./Assets/Products\ Page.png);

}
.aboutbannercolor{
  background-color: rgba(255, 255, 255, 0.809);
  padding: 50px 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;

}
.aboutbannercolor h1{
  font-family: "Outfit", sans-serif;

  font-size: 50px;
  font-weight: 400;
  text-align: center;
}
.aboutbannercolor p{
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
}
.aboutthejuices{
  display: flex;
  flex-direction: row;
}
.aboutthejuices-left , .aboutthejuices-right{
  width: 50%;
  padding: 50px;

}
.aboutthejuices-right{
  display: flex;
  justify-content: center;
  align-items: center;
}
.aboutthejuices-right img{
  width: 90%;
}
.aboutthejuices-left{
  display: flex;
  justify-content: center;
  gap: 30px;
  /* align-items: center; */
  flex-direction: column;
}
.aboutthejuices-left h3{
  font-family: "Outfit", sans-serif;

  font-size: 40px;
  font-weight: 600;
  color: rgba(239, 65, 54, 1);
}

.aboutthejuices-left h2{
  font-family: "Outfit", sans-serif;

  font-size: 50px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 30px;
 
}
.aboutthejuices-left p{
  font-family: "Outfit", sans-serif;
  color: rgba(61, 61, 61, 1);
  font-size: 18px;
  font-weight: 400;
}
.ourstory{
  text-align: center;
}
.ourstory img{
  width: 100%;
}
.ourstory h3{
  font-family: "Outfit", sans-serif;
  color: rgba(239, 65, 54, 1);
  font-size: 50px;
  font-weight: 600;
}
.ourstory h1{
  font-family: "Outfit", sans-serif;
 
  font-size: 50px;
  font-weight: 400;
  margin-bottom: 30px;
  margin-top: 10px;
}
.ourstory-p{
  padding: 50px 100px;
}
.ourstory-p p{
  font-family: "Outfit", sans-serif;
 color: rgba(61, 61, 61, 1);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}
.pd-banner{
  height: auto;
  padding-top: 150px;
  padding-bottom: 150px;
  background-position: center;
  background-size: cover;
    background-repeat: no-repeat;

  
  background-image: url(./Assets/Banner\ 11.svg);
}

.SingleProductDetail{
  display: flex;
  flex-direction: row;
}
.SingleProductDetail-left , .SingleProductDetail-right{
  width: 50%;
}
.main-img{
  width: 80%;
  
}
.main-img img{
  width:90%;
}
.side-box{
  height: 86px;
  width: 86px;
  border: 1px solid #EF4136;border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}
.side-box img{
  border-radius: 50%;
}
.SingleProductDetail-left{
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 50px;
  
}
.side-img{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  
}
.SingleProductDetail-right{
  padding: 50px;
}
.SingleProductDetail-right h1{
  font-size: 40px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;

}
.SingleProductDetail-right h2{
  font-size: 30px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
  margin-top: 10px;
  margin-bottom: 10px;
}
.SingleProductDetail-right p{
  font-size: 16px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
  color:  rgba(61, 61, 61, 1);
}

.custom-select{
  Width:
151px;
Height:
43px;
border: 1px solid black;
border-radius: 4px;
font-size: 16px;
font-weight: 400;
font-family: "Outfit", sans-serif;
}
.select-slot{
  display: flex;
  flex-direction: row;
  align-items: center;
gap: 20px;
}
.select-slot p{
  font-size: 20px;
font-weight: 400;
font-family: "Outfit", sans-serif;
width: 130px;
}
.qty-input{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
.qty-input p{
  font-size: 20px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
}
.qty-input-ip{
  border: 1px solid black;
  Width:
132px;
Height:
43px;
border-radius: 4px;
display: flex;
flex-direction: row;
justify-content: space-around;

}
.qty-input-ip input{
  width: 50px;
  font-size: 16px;
font-weight: 400;
font-family: "Outfit", sans-serif;
background: none;
  border: none;
  text-align: center;
}
.qty-input-ip button{
  font-size: 16px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
  background: none;
  border: none;
}
.fvt{
  Width:
  43px;
  Height:
  43px;
  border: 1px solid black;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addtocart{
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 10px;
  background-color: #FEEED2;

  color: #000000;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 700;
}
.buynow{
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 10px;
  background: none;
  /* background-color: rgba(255, 207, 6, 1); */
  background-color: #0CB90A;
  color: white;
}
.paym{
  width: 100%;
}
.SingleProductDetail-right h3{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  font-size: 16px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
}
.SingleProductDetail-right h4{
  font-size: 16px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
}
.Description{
  padding: 150px;
}
.tabs button{
  padding: 15px 20px;
  border: none;
  background-color: rgba(255, 248, 238, 1);
  font-size: 16px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
  color: black;
  
}

.active{
  background-color: rgba(255, 207, 6, 1) !important;
  color: white !important;
}
.tab-content{
  border: 1px solid rgba(217, 217, 217, 1);
  padding: 50px;
}
.tab-div-left{
  width: 50%;
}
.tab-div-left h1{
  font-size: 31px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
}
.tab-div-left p{
  font-size: 16px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
}
.tab-div-right{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

}
.tab-div-right img{
  width: 90%;
}
.tab-div{
  display: flex;
  flex-direction: row;

}
.check-heading{
  font-size: 50px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
}
.checkout-div{
  display: flex;
  flex-direction: row;
}
.checkout-div-left{
  width: 50%;
  padding: 50px;
}
.checkout-div-right{
  width: 50%;
}
.checkout-contact{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.checkout-contact p{
  font-size: 18px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
}
.checkout-contact h4{
  font-size: 14px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
}
.name-inps{
  display: flex;
  flex-direction: row;
  gap: 30px;
}
.name-inps input{
  width: 100%;
  height: 40px;
  padding: 0px 16px 0px 16px;
  border: 1px solid rgba(96, 96, 96, 1);
}
.checkout-div-left input{
  width: 100%;
  height: 40px;
  padding: 0px 16px 0px 16px;
  border: 1px solid rgba(96, 96, 96, 1);
  margin-top: 20px;
}
.map{
  width: 100%;
}
.checkout-div-left h3{
  font-size: 18px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
}
.continue-btn{
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.continue-btn button{
  width: 50%;
  height: 60px;
  border: none;

}
.Return{
background: none;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
gap: 30px;
}
.CONTINUESHIPPING{
  border-radius: 10px;
  background-color: rgba(255, 207, 6, 1);
  color: white;
  color: white;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 700;
  width: 250px !important;
}
.checkout-div-right{
 display: flex;
 flex-direction: column;
 align-items: center;
}
.checkout-div-right h2{
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
}
.cart-item{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;

  justify-content: space-between;
}

.cart-item-text h3{
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
  font-family: "Outfit", sans-serif;
}
.cart-item-text p{
  font-size: 16px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
}
.cart-ip{
  Width:
100%;
Height:
34.79px;

border-radius: 4px;
display: flex;
flex-direction: row;
justify-content: space-evenly;
align-items: center;
margin-top: 10px;
background-color: rgba(255, 207, 6, 1);
color: white;

}
.cart-ip input{
  width: 
  50%;
  background: none;
  border: none;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
}
.cart-ip button{
  background: none;
  border: none;
  font-size: 16px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
}
.cart-total{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}
.cart-total p{
  font-size: 18px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
}

.cart-total h3{
  font-size: 18px;
  font-weight: 700;
  font-family: "Outfit", sans-serif;
}
.cont-info{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;

}
.cont-info h4{
  font-size: 18px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
}
.cont-info p{
  font-size: 14px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
  color: rgba(116, 140, 112, 1);
}
.line2{
  height: 1px;
  width: 100%;
  background-color: rgba(223, 223, 223, 1);
}
.checkout-div-left h2{
  font-size: 20px;
  font-weight: 700;
  font-family: "Outfit", sans-serif;
}
.checkout-div-left h6{
  font-size: 16px;
  font-weight: 700;
  font-family: "Outfit", sans-serif;
}
.payment-div{
padding: 50px;
display: flex;
flex-direction: row;
}
.payment-div-left{
  width: 50%;
}
.payment-div-right{
  width: 50%;
}
.payment-div-left h2 , .payment-div-right h2{
  font-size: 16px;
  font-weight: 700;
  font-family: "Outfit", sans-serif;
  margin-bottom: 20px;
}
.payment-check {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.payment-check p{
  font-size: 16px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
}

.payment-ips{
  display: flex;
  flex-direction: column;
  padding: 30px;

}
.payment-ips input{
  height: 40px;
  border: 1px solid rgba(96, 96, 96, 1);
  padding-left: 20px;

margin-bottom: 20px;
}
.payment-div-right p{
  font-size: 16px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
}
.card-details{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.card-details p{
  width: 150px;
}

.card-details input{
  height: 40px;
  border: 1px solid rgba(96, 96, 96, 1);
  
}
.payment-div-right button{
  background-color: rgba(255, 207, 6, 1);
  border-radius: 10px;
  color: white;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 700;
  border: none;
  width: 100%;
  height: 60px;
}
.payment-done{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
}
.payment-done h1{
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  font-weight: 700;
}
.payment-done p{
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: rgba(12, 12, 12, 1);
}
.payment-done button{
  background-color: rgba(255, 207, 6, 1);
  border-radius: 51px;
  color: white;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 700;
  border: none;
  width: 300px;
  height: 60px;
}
.profile{
  background-color: rgba(255, 248, 238, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}
.profile h1{
  font-size: 32px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
  margin-top: 15px;
  margin-bottom:5px;
}
.profile p{
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;

  color: rgba(128, 125, 126, 1);
}
.tab-content2{
  padding: 50px 150px;
}
.personal-info h2{
  font-size: 28px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  
}
.personal-info p{
  font-size: 22px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  margin-top: 10px;
  
}
.detail-info p{
  color: rgba(128, 125, 126, 1);
  font-weight: 400;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 5px;

}

.detail-info h3{
  color: rgb(0, 0, 0);
  font-weight: 700;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;

}
.detail-info{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.detail-info input{
  width: 300px;
  height: 40px;
  border: 1px solid black;
  padding: 10px;
  border-radius: 8px;

}
.center{
  display: flex;
  flex-direction: row;
  justify-content: center;align-items: center;
  gap: 10px;
}
.center button{
  background-color: rgba(255, 207, 6, 1);
  border-radius: 10px;
  color: white;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 700;
  border: none;
  width: 300px;
  height: 60px;
}
.tabs-center{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.Dashboard-tab{
  /* background-color: rgba(255, 248, 238, 1); */
}
.Dashboard-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
.Dashboard-div-left{
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
  gap: 50px;
  /* background: linear-gradient(rgba(255, 146, 0, 0.81), rgba(255, 193, 0, 0.77)); */
}
.Dashboard-div-left h1 , .Dashboard-div-right h1{
  font-size: 31px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
}
.Dashboard-div-right{
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.dashboard-product{
  /* Width:
320px; */
Height:
451px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
border-radius: 10px;
background: linear-gradient(rgba(255, 146, 0, 0.81), rgba(255, 193, 0, 0.77));
box-shadow: 10px 10px 15px -13px rgba(0,0,0,0.75);
}
.dashboard-product img{
  width:60%;
border-radius: 10px;

}
.dashboard-product p{
  font-size: 20px;
  font-weight: 500;
  font-family: "Outfit", sans-serif;
  text-align: center;
  margin-top: 10px;
  color: white;
}
.Nutrition-table{
  height: 52px;
  background-color: rgba(255, 248, 218, 1);
  color: rgb(0, 0, 0);
  width: 500px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
  box-shadow: 10px 10px 15px -13px rgba(0,0,0,0.75);
}
.Nutrition-table p{
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;

}
.Nutrition-table2{
  height: 52px;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  width: 50%;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
}
.Nutrition-table2 p{
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
}
.footer{
  background-color: rgba(30, 50, 52, 1);
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 100px;
  padding-bottom: 100px;
  gap: 30px;
  padding-left: 30px;
  padding-right: 30px;
}
.footer ul{
  width: 30%;
}
.footer p{
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.footer h3{
  font-size: 25px;
  font-weight: 700;
  font-family: "Outfit", sans-serif;
}
.footer ul li{
  list-style: none;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 15px;
}
.bottom-footer{
  background-color: rgba(255, 207, 6, 1);
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
}
.bottom-footer p{
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.social-btn{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.Contact{
  display: flex;
  flex-direction: row;
  padding-top: 50px;
  padding-bottom: 50px;
}
.Contact-left , .Contact-right{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Contact-left img{
  width: 80%;
}
.Contact-form{

  width: 80%;
  background-color: rgba(255, 248, 238, 1);
  padding: 50px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
}
.Contact-form label{
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
  margin-top: 10px;
}
.Contact-form input{
  height: 47px;
  border: none;
  padding-left: 15px;
}
.Contact-form button{
  background-color: rgba(255, 207, 6, 1);
  border-radius: 10px;
  color: white;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 700;
  border: none;

  height: 50px;
}
.Contact-form h2{
  font-family: "Outfit", sans-serif;
  font-size: 30px;
  font-weight: 400;
  text-align: center;
}

/* Nav */

.bi-list{
  color: rgba(255, 207, 6, 1);
  font-size: 25px !important;
  display: none;
}
.menu-btns , .bi-basket2-fill{
  display: none !important;
  text-decoration: none !important;
}
.bi-basket2-fill{
  font-size: 23px;
  color: rgba(255, 207, 6, 1);
}
.none{
  text-decoration: none;
}
@media(max-width:855px){
  .menu-btns , .bi-basket2-fill{
    display: flex !important;
  }
  nav{
    padding-left: 20px;
    padding-right: 20px;
  }
  nav ul{
    display: none;
    }
    .bi-list{
      display: block;
    }

}
.bi-list {
  cursor: pointer;
}

/* Styles for the toggleable div */
.toggleDiv {
  width: 100%;
  height: 100%;
  margin-top: 20px;
  padding: 20px;
  background-color: #000000d4;
  border: 1px solid #dddddd;
  border-radius: 50px;
  color: white;
  text-align: center;
margin-top: 150px;
padding-top: 50px;
background: linear-gradient(rgba(255, 146, 0, 0.81), rgba(255, 193, 0, 0.77));

}
.toggleDiv ul li{
  font-family: "Manrope", sans-serif;
  color: white;

  font-weight: 700;
  font-size: 16px;
  list-style: none;
  margin-bottom: 25px;
}
.toggleDiv-ops{
  width: 100%;
  height: 100vh;
  background-color: #000000c1;
  position: fixed;
z-index: 100;
  top: 80px;
}
.nan{
  text-decoration: none;

  color: black;
}
@media(max-width:768px){
.banner{
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 30px;
  padding-right: 30px;

  background-position: right;
}
.banner h1{
  font-size: 40px;

}
.second-banner{
  flex-direction: column;
}
.second-banner-left{
  width: 100%;
  padding: 30px;


}
.second-banner-left h1{
  font-family: "Outfit", sans-serif;

  font-size: 40px;
  font-weight: 400;
}
.second-banner-right{
width: 100%;

}
.ProductCategories h1 , .products-div h1 , .Testimonials h1 , .Blog-div h1  {
  font-size: 40px;

}


}

@media(max-width:500px){
.calories-count-div{
flex-direction: column;
gap: 0px;

}
.second-banner-left{
padding: 20px;
}
}

.prod-cat-cards {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px 30px;
  gap: 70px; /* Optional: Adds space between cards */
}

.prod-cat-card {
  display: inline-block;
  flex: 0 0 auto; /* Prevents shrinking and growing */
  text-align: center;
}


@media(max-width:780px){
.product-cards{
  padding: 20px;
}
.product-card{
  Width: 40%;
  height: auto;
  padding-bottom: 20px;
  /* padding-top: 20px;

  padding-left: 10px;
  padding-right: 10px; */
  text-align: center;
}
.product-card img{
  width: 100%;
  border-radius: 10px;

}
.product-card button{
  width: 80%;

}
.fruitpunch{
  flex-direction: column;
  padding: 20px;
  gap: 20px;
}
.fruitpunch-left , .fruitpunch-right{
width: 100%;
}
.Testimonials{
  background-color: rgba(255, 248, 238, 1);
  padding: 100px 30px;
}
.blog-cards{
  flex-direction: column;
  gap: 20px;
}
.blog-card{
  width: 100%;
 
}
.Blog-div{
  padding: 100px 20px;
}

.aboutbanner{
  padding-left: 50px;
  padding-right: 50px;
}
.aboutbannercolor{
  padding: 50px 50px;

}
}
@media(max-width:480px){
  .product-card{
    Width: 80%;
  }
}
@media(max-width:650px){
  .punch-card{
    width: 45%;
    }
}
@media(max-width:870px){
  .footer{
    flex-wrap: wrap;
  }
.footer ul{
  width: 30%;
}
}
@media(max-width:768px){
.f1{
  width: 45% !important;
}
.f2{
  width: 20% !important; 
}
.f3{
  width: 100% !important; 

}

}

@media(max-width:600px){
  .f1{
    width: 100% !important;
  }
  .footer{
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media(max-width:700px){
  .bottom-footer{
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-direction: column;
  gap: 20px;

  }

}
@media(max-width:970px){
.aboutthejuices-left h2{
  font-size: 40px;

}
.aboutthejuices-left{
  padding: 20px;
}

}
@media(max-width:768px){
.aboutthejuices{
  flex-direction: column;
}
.aboutthejuices-left , .aboutthejuices-right{
  width: 100%;
  padding: 20px;

}
.ourstory h1{
  font-size: 40px;
}
.ourstory-p{
  padding: 50px 20px;
  text-align: left;
}

}
@media(max-width:500px){
  .aboutbanner{
    padding-left: 20px;
    padding-right: 20px;
  }
  .aboutbannercolor h1{
    font-size: 30px;

  }
  .aboutbannercolor p{
    font-size: 15px;
  }
  .aboutbannercolor{
    /* background-color: rgba(255, 255, 255, 0.3); */
    padding: 50px 30px;
  }
}

@media(max-width:800px){
  .SingleProductDetail{
    flex-direction: column;
  }
  .SingleProductDetail-left , .SingleProductDetail-right{
    width: 100%;
  }
  .SingleProductDetail-left{
    flex-direction: column;
  }
  .side-img{
    width: 100%;
    display: flex !important;
    flex-direction: row !important;
    overflow-x: auto;
    justify-content: start;
    margin-top: 30px;
    

  }
  .Contact{
    flex-direction: column;
    gap: 50px;
  }
  .Contact-left , .Contact-right{
    width: 100%;
}
.Contact-form{
  width: 90%;
}

}

@media(max-width:1000px){
  /* .tab-div{
    display: flex;
    flex-direction: column;
  } */
  .Description{
    padding: 50px;
  }
}

@media(max-width:768px){
  .tab-div{
    flex-direction: column;
    gap: 50px;
  }
  .tab-div-left{
    width: 100%;
  }
  .tab-div-right{
    width: 100%;

  }
}
@media(max-width:600px){
.tab-content , .Description{
  padding: 20px;

}


}
@media(max-width:970px){
  .checkout-div-left{
    width: 40%;
    padding: 30px;
  }
  .checkout-div-right{
    width: 60%;
  }
  .cart-item{
    width: 70%;
  }
}
.nut-table{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
}
@media(max-width:768px){
  .checkout-div{
    flex-direction: column;
  }
  .checkout-div-left{
    width: 100%;
    padding: 20px;
  }
  .checkout-div-right{
    width: 100%;
    /* padding: 20px; */

  }
  .cart-item{
    width: 100%;
  }
.cart-total{
  width: 90%;
}
.payment-div{
  flex-direction: column;
}
.payment-div-left{
  width: 100%;
}
.payment-div-right{
  width: 100%;
}
.payment-div{
  padding: 20px;
}
.tab-content2{
  padding: 50px 20px;
}
.Dashboard-div{
  flex-direction: column;
}
.Dashboard-div-left{
  width: 100%;

}
.Dashboard-div-right{
width: 100%;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
}
.Nutrition-table , .Nutrition-table2{
  width: 300px;
}
}

.download-banner{
  background-color: rgba(254, 238, 210, 1);
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  height: 100vh;

}
.download-banner-left , .download-banner-right{
  width: 50%;
}
.download-banner-left {
  padding-top: 100px;
  padding-bottom: 50px;
}
.download-banner-right{
  display: flex;
  justify-content: center;
}
.download-banner-right img{
  width: 50%;
}
.download-banner-left h1{
  font-family: "Outfit", sans-serif;

  font-size: 61px;
  font-weight: 400;
}
.download-banner-left{
  padding-left: 50px;
}
.download-banner-left h1 span{
  color: rgba(239, 65, 54, 1);
}
.download-banner-left p{
  font-family: "Outfit", sans-serif;

  font-size: 16px;
  font-weight: 400;
  color: rgba(61, 61, 61, 1);
}
.store-btn{
  display: flex;
  gap: 10px;
}
@media(max-width:900px){
  .download-banner-left h1{
    font-family: "Outfit", sans-serif;
  
    font-size: 40px;
    font-weight: 400;
  }
  .download-banner{
    padding-top: 20px;
  }
}
@media(max-width:768px){
  .download-banner{
    background-color: rgba(254, 238, 210, 1);
   height: auto;
    display: flex;
    flex-direction: column;
  
  }
  .download-banner-left , .download-banner-right{
    width: 100%;
  }
  .download-banner-left{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
  }

}
.homeproduct-cards{
display: flex;
flex-direction: row;
gap: 20px;
padding-left: 20px;
padding-right: 20px;
justify-content: center;
}
.homeproduct-card{
  Width:
320px;
Height:
auto;
background-color: rgba(254, 238, 210, 1);
border-radius: 14px;
display: flex;
justify-content: center;align-items: center;
flex-direction: column;
padding-bottom: 15px;

}
.homeproduct-cards-div{
  display: flex;
  overflow-x: auto;
  flex-direction: row;
  gap: 20px;

}

.homeproduct-card img{
  width: 100%;
  border-radius: 10px;
}
.homeproduct-card p{
  font-family: "Outfit", sans-serif;
  font-size: 20px;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 10px;
}
.homeproduct-card h3{
  font-family: "Outfit", sans-serif;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;

}
.homeproduct-card button{
  width : 163px;
  height : 42px;
  background-color: rgba(255, 207, 6, 1);
  color: white;
  border-radius: 10px;
  border: none;
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  font-weight: 700;
}
.homeproduct-cards {
  /* display: flex;
  overflow-x: auto; */


}

.homeproduct-card {
  flex: 0 0 auto;
  
}
.plans{
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 50px;
  padding-right: 50px;
}
.plans p{
text-align: center;
font-family: "Outfit", sans-serif;
font-size: 16px;
font-weight: 400;
color: rgba(61, 61, 61, 1);

}
.plan-cards{
display: flex;
flex-direction: row;
justify-content: center;
gap: 50px;
}
.plan-card{
  width: 30%;
  height: auto;
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.05);
  padding-bottom: 50px;
  border: 1px solid rgba(253, 187, 82, 0.2);
  border-radius: 12px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.plan-heading{
  height: 57px;
  border-bottom: 1px solid rgba(97, 97, 99, 0.3);
text-align: center;
display: flex;
align-items: center;
justify-content: center;
background-color: rgba(255, 207, 6, 1);


}
.plan-heading h2{
  font-family: "Outfit", sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
}
.plan-text{

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.plan-text h3{
  font-family: "Outfit", sans-serif;
font-size: 25px;
font-weight: 500;
text-align: center;
margin-top: 10px;
}
.plan-text h3 span{
  color: rgba(175, 175, 175, 1);
  font-size: 20px;
  font-weight: 500;
}
.plan-text-lines{
width: 80%;
border-bottom: 1px solid rgba(128, 125, 126, 1);
padding-bottom: 15px;
}
.plan-text-lines p{
  font-family: "Outfit", sans-serif;
  font-size: 17px;
  font-weight: 400;
  color: rgba(128, 125, 126, 1);
text-align: left !important;
display: flex;
align-items: center;
gap: 15px;

}
.plan-text-lines p i{
  color: rgb(18, 180, 18);
}
.plan-text button{
  width: 161px;
  height: 44px;
  background-color: rgba(255, 207, 6, 1);
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  font-weight: 600;
  border: none;
color: white;
}
@media(max-width:768px){
  .plan-cards{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    }
    .plan-card{
      width: 100%;


}
}
.newproducts{
  display: flex;
  flex-direction: row;
  width: 100%;
}
.newproducts-card{
  Width:
350px;
Height:
536px;
background-color: rgba(254, 238, 210, 1);
border-radius: 14px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding-bottom: 20px;
padding-top: 100px;
padding-bottom: 100px;
}
.newproducts-card button{
  /* height: 42px;
  width: 163px; */
  border-radius: 10px;
  background-color: rgba(255, 207, 6, 1);
  border: none;
  font-size: 16px;
  font-weight: 700;
  font-family: "Outfit", sans-serif;
  color: white;
  /* padding-top: 15px;
  padding-bottom: 15px; */
  padding: 10px;

}
.newproducts-card p{
  font-size: 20px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
  color: black;
}
.newproducts-card h4{
  font-size: 20px;
  font-weight: 600;
  font-family: "Outfit", sans-serif;
  color: black;
  margin-top: 10px;
  margin-bottom: 10px;

}

.newproducts-cards {
  width: 30%;
  height: 110vh; /* Set a fixed height */
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto; /* Enable vertical scrolling */
}
.new-product-right{
  padding: 20px;
  width: 100%;
 
}
.new-product-right-cards{
  display: flex;
  flex-direction: row;
  justify-content: space-around;

}
.product-carousel {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    gap: 10px;
}

.product-container {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
    gap: 50px;
    padding-bottom: 20px;
}

.product-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.product-card {
    flex: 0 0 auto;
 
}

.product-card img {
    max-width: 100%;
    height: auto;
}

.nav-button {
    /* background-color: #fff;
    border: 1px solid #ddd; */
    padding: 10px;
    cursor: pointer;
    user-select: none;
    background: none;
    border: none;
    font-size: 50px;
}
.slot-div{
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.slotlabel{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.Terms{
 padding: 50px 100px;
  text-align: center;
}
.Terms p{
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: rgba(30, 50, 52, 1);
}
.Terms2{
  background-color: rgba(254, 238, 210, 1);
  padding: 50px 100px;
}
.Terms2 h2{
  font-family: "Outfit", sans-serif;

  font-size: 30px;
  font-weight: 600;
}
.Terms2 p{
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: rgba(30, 50, 52, 1);
}
@media(max-width:768px){
  .Terms2{
    background-color: rgba(254, 238, 210, 1);
    padding: 50px 30px;
  }
  .Terms{
    padding: 50px 30px;
     text-align: center;
   }
   .Terms h1{
    font-size: 40px;
   }
}
@media(max-width:768px){
  .Terms{
    padding: 30px 30px;
     text-align: start;
   }
   .Terms h1{
    font-size: 40px;
   }
}
.links{
  text-decoration: none;
  color: white;
}
.logo{
  height: 70px;
}
.check-cart{
width: 80%;

display: flex;
justify-content: center;
gap: 100px;
align-items: center;
padding: 20px;
}
.check-cart img{
  height: 200px;
}
.check-cart h3{
  font-size: 25px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
}
.check-cart p{
  font-size: 15px;
  font-weight: 300;
  font-family: "Outfit", sans-serif;
}
@media(max-width:900px){
  .check-cart{
    width: 90%;
    gap: 30px;
  }
}
@media(max-width:768px){
  .check-cart{
    flex-direction: column;
    align-items: center;

    width: 100%;
    
  }
  .new-cart-data{
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    
  }
}
@media(max-width:550px){
.check-cart img{
  width: 100%;
}
}

/* Side Navbar */
/* Styles for the slider */
.slider {
  position: fixed;
  top: 0;
  left: -250px; /* Hidden by default */
  width: 250px;
  height: 100%;
  background-color: #ffffff;
  overflow-x: hidden;
  transition: 0.3s;
  padding-top: 60px;
  z-index: 1000; /* Ensure it is above other elements */
  padding-left: 10px;
  padding-right: 10px;
}

.slider a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
background-color: #FFF8EE;
  color: #010101;
  display: block;
  transition: 0.3s;
  font-family: "Outfit", sans-serif;
  font-size: 18px;
  font-weight: 400;
  border-radius: 10px;
  margin-top: 10px;
  height: 40px;
}

.slider a:hover {
  color: #f1f1f1;
}

.slider .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

/* When the slider is open */
.slider-open {
  left: 0;
}
/* Side Navbar */

@media(max-width:855px){
  .logo{
    height: 60px;
  }
}
.nan.active {
  color: #FC5307 !important;
  background: none !important;
  /* color: white; */
  font-weight: 700;
}
.new-cart-card{
  width: 300px;
 border: 1px solid rgb(228, 228, 228);
padding: 20px;
border-radius: 15px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
box-shadow: 1px 2px 16px -10px rgba(0,0,0,0.75);

}
.new-cart-card img{
  height: 200px;
}
.new-cart-cards{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row !important;
  flex-wrap: wrap;
  width: 100%;
  gap: 40px;
}
.card-p{
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  font-family: "Outfit", sans-serif;
  
    font-size: 15px;
    font-weight: 400;
}
.new-cart-card h2{
  font-family: "Outfit", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: rgba(239, 65, 54, 1);

}
.newaddselect{
  margin-top: 10px !important;
  width: 100% !important;
  color: white !important;
  background-color: rgba(255, 207, 6, 1) !important;
}
.product-container-left h2{
  font-family: "Poppins", sans-serif !important;
  font-size: 20px;
  font-weight: 500;

}
.product-container-left{
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.product-container-right{
  background-color: rgba(254, 240, 239, 1);
  border-radius: 10px !important;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  border-radius: 20px;

}

.small{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  
  height: 100%;
}
.dayCount{
display: flex;
justify-content: center;
align-items: center;
gap: 20px;
padding-bottom: 20px;
}
.dayCount-complete{
width: 200px;
height: 200px;
background-color: rgba(255, 248, 218, 1);
border-radius: 10px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
gap: 20px;
}
.dayCount-remaning{
  width: 200px;
  height: 200px;
background-color: rgba(254, 240, 239, 1);
border-radius: 10px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
gap: 20px;


}
.complete{
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: rgba(255, 207, 6, 1);

  display: flex;
justify-content: center;
align-items: center;color: white;
font-family: "Poppins", sans-serif !important;
font-size: 18px;
font-weight: 500;
}
.dayCount-complete p{
  font-family: "Poppins", sans-serif !important;
  font-size: 15px;
  font-weight: 500;
}
.dayCount-remaning p{
  font-family: "Poppins", sans-serif !important;
  font-size: 15px;
  font-weight: 500;
}
.remaning{
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: rgba(239, 65, 54, 1);

  display: flex;
justify-content: center;
align-items: center;color: white;
font-family: "Poppins", sans-serif !important;
font-size: 18px;
font-weight: 500;
}
.dayCount-remaning{}
.cell{
  height: 85px;
background: none;
color: white;
  width: 100px;
  display: flex;
  /* justify-content: space-between; */
  border: 2px solid #EF4136;
  /* border-radius: 10px; */
  flex-direction: column;
  font-family: "Poppins", sans-serif !important;
font-size: 15px;
font-weight: 500;
/* border-radius: 10px; */

}
@media(max-width:768px){
  .product-container{
    flex-direction: row;
  }
  .product-container-left , .product-container-right{
    width: 100%;
  }

  .product-container-right{
    
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    border-radius: 20px;
  }
  .cell{
    /* background-color: rgba(255, 207, 6, 1); */
    color: white;
      width: 100%;
      display: flex;
      justify-content: space-between;
      border: 2px solid white;
      /* border-radius: 10px; */
 
    }
}
@media(max-width:550px){
  .product-container-right{
    width: 99% !important;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    border-radius: 20px;

  }
  .dayCount{
    flex-direction: column;
    gap: 20px;
  }
}
.push-resume{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  height: 50vh;
}
.push-resume button{
  height: 60px;
  width: 271px;
  border-radius: 30px;
  
}
.Resume{
  background-color: green;
  border: 2px solid rgba(255, 207, 6, 1);
  color: rgb(255, 255, 255);
  font-family: "Poppins", sans-serif !important;
font-size: 18px;
font-weight: 500;
width: 150px;
border-radius: 10px;
height: 40px;
}
.Pause{
  background-color: rgba(239, 65, 54, 1);
  border: 2px solid rgba(239, 65, 54, 1);
  color: rgb(255, 255, 255);
  font-family: "Poppins", sans-serif !important;
font-size: 18px;
font-weight: 500;
width: 150px;
border-radius: 10px;
height: 40px;
}
.nameing{
  color: white;
  font-family: "Poppins", sans-serif !important;
  font-size: 15px;
  font-weight: 500;
}
.cal-close{
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  background-color: #EF4136;
  border-radius: 10px;
  color: white;
  font-family: "Poppins", sans-serif !important;
  font-size: 15px;
  font-weight: 500;
  border: none;

}
.logo2{
  height: 100px;
}
.socialback{
  height: 35px;
  width: 35px;
  background-color: white;border-radius: 50%;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tabs2 button{
  width: 100px;
}
.tabs2 button i{

}

.slot-div {
  display: flex;
  flex-direction: row !important;
}

.radio-container {
  width: 150px;
  text-align: center;
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-family: "Poppins", sans-serif !important;
  font-size: 18px;
  font-weight: 400;
  user-select: none;
  /* background-color: #EF4136;  */
  padding: 10px; /* added padding for better visual effect */
  border-radius: 5px; /* added border radius for rounded corners */
  transition: background-color 0.3s ease; /* smooth transition for background color */
color: #EF4136;
border: 1px solid #EF4136;
}

.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  /* position: absolute; */
  display: none;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

.radio-container:hover input ~ .checkmark {
  background-color: #d00000;
}

.radio-container.checked {
  background-color: rgba(255, 207, 6, 1) !important;
  border: 1px solid rgba(255, 207, 6, 1) !important;
  font-weight: 600;
  color: white;

}

.radio-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radio-container input:checked ~ .checkmark:after {
  display: block;
}

.radio-container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}


.radio-container input:checked ~ .checkmark:after {
  display: block;
}

.radio-container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
.slot-div{
  display: flex;
  flex-direction: row;
}
.slot-div {
  display: flex;
  flex-direction: column;
}

.slotlabel {
  width: 150px;
  text-align: center;
  display: block;
  position: relative;
  padding-left: 35px;
  /* margin-bottom: 12px; */
  cursor: pointer;
  font-family: "Poppins", sans-serif !important;
font-size: 18px;
font-weight: 400;
  user-select: none;
border: 1px solid #EF4136;
  padding: 10px; /* added padding for better visual effect */
  border-radius: 5px; /* added border radius for rounded corners */
  transition: background-color 0.3s ease; /* smooth transition for background color */
color: #EF4136;
}

.slotlabel input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

.slotlabel:hover input ~ .checkmark {
  background-color: #ccc;
}

.slotlabel.checked {
  background-color: rgba(255, 207, 6, 1) !important;
  border: 1px solid rgba(255, 207, 6, 1) !important;
  font-weight: 600;
  color: white;

}
.datepiker{
  /* border: 1px solid rgba(255, 207, 6, 1); */
  height: 40px;
  text-align: center;
  border-radius: 10px;
  /* background-color: rgba(255, 207, 6, 1); */
  color: white;
  font-family: "Poppins", sans-serif !important;
font-size: 18px;
font-weight: 400;
}

.datepicker-unselected {
  height: 40px;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #EF4136;
  color: #EF4136;
  font-family: "Poppins", sans-serif !important;
  font-size: 18px;
  font-weight: 400;
  z-index: 100;
}
.datepicker-unselected::placeholder{
  color: #EF4136;

}

.datepicker-selected {
  height: 40px;
  text-align: center;
  border-radius: 10px;


  font-family: "Poppins", sans-serif !important;
  font-size: 18px;
  font-weight: 500;
  color: white;
  background-color: rgba(255, 207, 6, 1);
  border: 1px solid rgba(255, 207, 6, 1); /* Change the border color to green if date is selected */
}
.slotlabel .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.slotlabel input:checked ~ .checkmark:after {
  display: block;
}

.slotlabel .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

@media(max-width:550px){
  .select-slot{
    display: flex;
    flex-direction: column !important;
    /* text-align: center; */


  }
.select-slot p{
width: 100% !important;
  
}
.datepiker, .react-datepicker-wrapper{
  width: 100% !important;
}

}
.update-cards-div{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
@media(max-width:768px){
  .update-cards-div{
    justify-content: start;
  }
  .product-carousel {
    /* flex-direction: column; */
    gap: 0;
  }
.nav-button {
  font-size: 30px;
  padding: 5px;

}
.product-container {
  gap: 30px;

}

.product-card{
  width: 200px;
}
/* .nav-button{
  position: fixed;
} */
}
.newproducts-card-10{
  width: auto;
}
.new-product-right-10 , .newproducts-card-10{
  background-color: white !important;
}
.product-card-10{
  width: 200px;
  height: auto;
  padding-bottom: 20px;
}


@media(max-width:600px){
  .newproducts-card{
    /* Width:
  50% !important; */
  Height:auto;
  padding-top: 10px !important;
padding-bottom: 10px !important;
padding-left: 10px;
padding-right: 10px;
  }
  .newproducts-card img{
    width: 100px !important;
  }
.newproducts-card p{
  font-size: 15px;
  text-align: center;

}
.newproducts-card h4{
  font-size: 15px;
  text-align: center;
}
.product-card{
  width: 130px;
}
}
.loader-div{
  display: flex;
  justify-content: center;
  align-items: center;
}


/* .loader {
  width: 50px;
  aspect-ratio: 1;
   --c:no-repeat linear-gradient(#000 0 0);
   --m:no-repeat radial-gradient(circle closest-side,#0000 92%,#000);
  background: 
    var(--c) 0    50%,
    var(--c) 50%  50%, 
    var(--c) 100% 50%;
  background-size: 8px calc(50% + 8px);
  -webkit-mask: var(--m),var(--m),var(--m);
  -webkit-mask-size: 8px 150%;
  animation: l12 1s infinite alternate;
}
@keyframes l12 {
  0%,
  100% {-webkit-mask-position: 0 50% ,50% 50% ,100% 50% }
  20%  {-webkit-mask-position: 0 100%,50% 50% ,100% 50% }
  40%  {-webkit-mask-position: 0 0   ,50% 100%,100% 50% }
  60%  {-webkit-mask-position: 0 50% ,50% 0   ,100% 100%}
  80%  {-webkit-mask-position: 0 50% ,50% 50% ,100% 0   }
} */
.product-card2{
  height: auto;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
@media(max-width:768px){
  .product-card2{
    Width:
    200px;
    Height:
    350px;
  }
  .product-card2 p{
    font-family: "Outfit", sans-serif;
    
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .product-container2{
    flex-direction: column;
  }
  .product-card button{
font-size: 12px;
  }
}
.cont-icon{
  height: 60px;
  width: 60px;
  background-color: white;
  border: 3px solid #EF4136;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #EF4136;
  font-size: 20px;
}
.cont-icon-div{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}
.cont-icon-div h3{
  font-family: "Poppins", sans-serif !important;
  font-size: 18px;
  font-weight: 500;
}
@media(max-width:550px){
  .cont-icon{
    height: 50px;
    width: 50px;
  font-size: 18px;

  }
.cont-icon-div{
  gap: 20px;
}
.cont-icon-div h3{
  font-size: 15px;

}
.Contact-form{
  padding: 30px;

}

.newproducts-card-10 img{
  width: 90px;

}
}

.punch-icon{
  height: 70px;
  width: 100%;

  /* border: 3px solid #EF4136; */

  /* margin-bottom: 10px; */
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;


}
.punch-icon p{
  color: #EF4136;
  font-family: "Poppins", sans-serif !important;
  font-size: 25px;
  font-weight: 800;
}
.punch-icon h6{
  color: #EF4136;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
  font-weight: 400;
  margin-top: 0;
}
.punch-icon h5{
  color: #EF4136;
  font-family: "Poppins", sans-serif !important;
}
.free{
  height: 50px;
}
.punch-icon i{
  color: #EF4136;
  font-size: 30px;

}
.punch-icon2{
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: row;
width: 100%;
  color: #EF4136;
  font-size: 30px;
}
.download-banner-left h4{
  font-family: "Poppins", sans-serif;
  color: #EF4136;
  font-size: 18px;
  font-weight: 500;
}
@media(max-width:550px){
  .download-banner-left h4{
  text-align: center;

  }
}
.no{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
.no h1{
  font-size: 150px;
  font-family: "Poppins", sans-serif;
}

.no h1 span{
  color: #EF4136;

}
.no p{
  font-family: "Poppins", sans-serif;

  font-size: 18px;
  font-weight: 400;
}
@media(max-width:550px){
  .no h1{
    font-size: 80px;
    font-family: "Poppins", sans-serif;
  }
  
  .no h1 span{
    color: #EF4136;
  
  }
  .no p{
    font-family: "Poppins", sans-serif;
  
    font-size: 15px;
    font-weight: 400;
  }
  .homeproduct-card{
    Width:
  250px;
  Height:
  auto;
  }
}
.sing-pdt-btns{
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.menu-btns{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

}
@media(max-width:1000px){
  .banner{
    height: auto;
  }

}

.whatsapp{
  position: fixed;
  top: 85%;
  left: 90%;
  z-index: 1000;
}
.whatsapp img{
  height: 70px;

}
@media(max-width:768px){
  .whatsapp{
    position: fixed;
    top: 85%;
    left: 80%;
    height: 70px;
  }
}
@media(max-width:600px){
  .whatsapp{
    position: fixed;
    top: 85%;
    left: 75%;
    height: 70px;
  }
}
.glassbottel{
  height: 40px;
  font-family: "Poppins", sans-serif !important;
  font-size: 18px;
  font-weight: 400;
  border: none;
  background: none;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.glassbottel span{
  font-size: 10px;
  font-weight: 400;
}
.buytrial-btn{
  background-color: rgba(254, 240, 239, 1);
  color: #EF4136;

}
.plan-points{
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}
.plan-points ul{
  width: 100%;
  display: flex;
  /* justify-content: center;
  align-items: center;
  flex-direction: column; */
  flex-direction: column;
  padding: 0px 50px;
}
.plan-points ul li{
  font-family: "Poppins", sans-serif !important;
  font-size: 18px;
  font-weight: 500;
  /* width: 50%; */
  /* background-color: #EF4136; */
  margin-top: 15px;
  height: 40px;

list-style: disc;
  color: rgb(0, 0, 0);
  border-radius: 12px;
  /* box-shadow: 2px 1px 18px -8px rgba(0,0,0,0.75); */
  /* transition: background-color .5s ease-in; */

}
.plan-points h3{
  font-family: "Poppins", sans-serif !important;
  font-size: 18px;
  font-weight: 400;
  
}
/* .plan-points ul li:hover{
  background-color: rgba(255, 207, 6, 1);
} */
@media(max-width:768px){
.plan-points{
  padding: 20px;
}
.plan-points ul{
  padding: 0px 20px;

}
.plan-points ul li{
  width: 100%;
  font-size: 15px;
  font-weight: 400;
}
.plan-points h3{
  text-align: left;
  font-size: 15px;

}

}
.product-container102{
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.product-container101{
  display: flex;
  gap: 50px;

}

@media(max-width:768px){
  .product-container102{

  }
.product-container101{
  flex-direction: column;
}

}
.renew{
  width: 200%;
  background-color: #EF4136;
color: white;
border: 1px solid #EF4136;
font-family: "Poppins", sans-serif !important;
font-size: 20px;
font-weight: 400;
}
.payment-status{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.payment-status h1{
  font-family: "Poppins", sans-serif !important;
font-size: 40px;
font-weight: 600;
}
.payment-status p{
  font-family: "Poppins", sans-serif !important;
  font-size: 18px;
  font-weight: 400;
}
.payment-status button{
  padding: 15px 25px;
  border-radius: 10px;
  color: white;
background: linear-gradient(rgba(255, 146, 0, 0.81), rgba(255, 193, 0, 0.77));
border: none;
font-size: 18px;
font-weight: 600;
font-family: "Poppins", sans-serif !important;
box-shadow: 10px 10px 15px -13px rgba(0,0,0,0.75);

}
@media(max-width:550px){
.fruitpunch-right{
  padding: 0px;
}
.fruitpunch-left{
  padding: 20px;


}
.trial-pack-card{
  width: 80%;

}
}

.cart-ip-border{
  height: 100%;
  width: 35%;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media(max-width:600px){
  .trial-pack-cards{
    flex-direction: row;
    align-items: start;
  gap: 20px;

  }
  .trial-pack-card{
    height: 200px;
  }
  .fruitpunch-left{
    padding: 0;
  }
}


.accordion {
  width: 100%;
  /* max-width: 600px; */
  margin: 0 auto;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
}

.accordion-item {
  /* border-bottom: 1px solid #ccc; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.accordion-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
background-color: rgba(255, 207, 6, 1);
  border-radius: 12px;
color: white;
  width: 60%;
height: 60px;
font-size: 15px;
font-weight: 400;
font-family: "Poppins", sans-serif !important;
padding-left: 20px;
padding-right: 30px;
}
.plus{
font-size: 25px;

}
.accordion-title h3 {
  margin: 0;
}

.accordion-content {
  padding: 10px;
  background-color: #fff;
}

.accordion-item:last-child {
  border-bottom: none;
}

@media(max-width:600px){
.accordion-title {
  width: 80%;
}

}

.loader {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-logo {
  width: 100px; 
  /* height: 100px; */
  position: absolute;
}

.spinner {
  width: 150px;
  height: 150px;
  border: 10px solid rgba(0, 0, 0, 0.1);
  border-top: 10px solid #FC5307;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.misson-div{
  background: linear-gradient(to top, #ff4e50, #f9d423); 

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-left: 450px;
  padding-right: 450px;
  padding-bottom: 15px;
}

@media(max-width:1300px){

  .misson-div{
    background: linear-gradient(to top, #ff4e50, #f9d423); 

    height: 100vh;
    display: flex;
    flex-direction: column;
    padding-left: 300px;
    padding-right: 300px;
  }
  }
  @media(max-width:1100px){
  
    .misson-div{
      background: linear-gradient(to top, #ff4e50, #f9d423); 

      height: 100vh;
      display: flex;
      flex-direction: column;
      padding-left: 250px;
      padding-right: 250px;
    }
    }
    @media(max-width:950px){
  
      .misson-div{
        background: linear-gradient(to top, #ff4e50, #f9d423); 
        height: 100vh;
        display: flex;
        flex-direction: column;
        padding-left: 200px;
        padding-right: 200px;
    
      }
      }

.misson-logo{
  border-radius: 100%;
  height: 100px;
  width: 100px;
  background-color: white;
  box-shadow: 0px 4px 4px 0px #00000040;
display: flex;
justify-content: center;
align-items: center;

}
.misson-logo p{
  font-size: 18px;
  font-weight: 700;
  font-family: "Poppins", sans-serif !important;
  text-align: center;
color: #FFCF06;
}
.misson-div-left{
  width: 100%;
  height: 50%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
gap: 30px;
  flex-direction: column;
}
.misson-div-left h2{
  font-size: 40px;
  font-weight: 700;
  font-family: "Poppins", sans-serif !important;
  color: #EF4136;
text-align: center;


}
.misson-div-right{
  width: 100%;
  height: 60%;

  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
}
.misson-div-right2{
  height: 70%;
}
.mission-clip3{
  background-color: white;
  height: 100%;
  width: 100%;
  border-radius: 30px 30px 30px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding-left: 10px;
padding-right: 10px;
padding-bottom: 20px;
margin-bottom: 20px;
}
.mission-clip{
  background-color: white;
  height: 100%;
  width: 100%;
  border-radius: 30px 30px 30px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding-left: 20px;
padding-right: 20px;
padding-bottom: 50px;


}
.mission-clip img{
  position: absolute;
  /* top:10px; */
  margin-top: -500px;
  

}
.mission-clip h3{
  font-size: 30px;
  font-weight: 700;
  font-family: "Poppins", sans-serif !important;
  margin-top: 100px;
  color: #EF4136;

}
.mission-clip h3 span{
  font-size: 15px;
  font-weight: 500;
  color: black;
 


}
.mission-clip button{
  background-color: #FFCF06;
  width: 271px;
  height: 59px;
  border-radius: 30px;
  color: white;
  border: none;
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif !important;

}
.ig1{
  width: 20%;
  position: absolute;
  left: 0;z-index: 10;
}
.ig11{
  width: 25%;

}
.ig2{
  width: 20%;
  position: absolute;
  right: 0;
  bottom: 0;

}
.ig22{
  width: 40%;
}
.mission-clip img{
  width: 250px;
}
.ig3{
  position: absolute;
 margin-left: -350px;
/* margin-bottom: 0px; */
  /* bottom: 0; */
  width: 20%;
  /* margin-top: 50vh; */
  bottom: 0;
}
.ig3121{
 margin-left: -450px;
 width: 25%;

}
.mission-clip2 img{
  margin-top: -450px;
  width: 300px;
  z-index: 1;

}
@media(max-width:1050px){
  .mission-clip img{
    position: absolute;
    /* top:10px; */
    margin-top: -400px;
  
  }
  .mission-clip2 img{
    margin-top: -400px;

  }
.misson-div{
  padding-left: 150px;
  padding-right: 150px;
}
}
@media(max-width:768px){

  .ig2 , .ig1{
    display: none;

  
  }
  .mission-clip img{
    width: 200px;
    margin-top: -350px;
  }
  
  .mission-clip212{
    width: 300px !important;
    margin-top: -400px !important;
  }
  .doyouknow{
    font-size: 35px !important;
  }
  .ig3{
   display: none;
  }
  .misson-div{
    padding-left:00px;
    padding-right:00px;
  }
}
.missionpara{
  margin-top: -170px;
  background-color: #FFF8DA;
  border: 2px solid #FFCF06;
  
  padding: 0px 0;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 12px;
  /* clip-path: polygon(50% 2.45%, 100% 38.77%, 100% 96.55%, 0% 96.55%, 0% 38.77%); */
/* width: 400px; */
height: 20px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 20px;
/* padding-top: 60px; */
z-index: 0;


}
.missionpara p{
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 0;
}

.fruitconsumer{
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif !important;
  color: #EF4136;
}
.sct{
  height: 170px;
  transform: scaleX(-1);
  margin-top: -70px;
  /* position: absolute;
  left: 0;
  z-index: 0;
  bottom: 50px; */


}
.nebs{
  /* z-index: 10; */
  /* position: absolute;
  right: 0;
  margin-right: 45px; */
  margin-left: -20px;

}
.mission-btns{
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.mission-btns button{
  width: 100% !important;
  height: auto !important;
  padding: 10px 50px !important;

}
.mission-btns-no{
background-color: white !important;
border: 1px solid #EF4136 !important;
color: #EF4136 !important;
}
.sct-div{
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
}
.sct-div button{
  height: 59px;
  width: 150px !important;
  border-radius: 30px;
  border: none;
  color: white;
  background-color: #FFCF06;
  font-size:16px;
  font-weight: 700;
  font-family: "Poppins", sans-serif !important;
}
@media(max-width:500px){
  .missionpara{
    width: 100%;
height: 100px;
  }
  .missionpara p{
    font-size: 14px;
}
.misson-div-left h2{
  font-size: 25px;
}
.misson-div-left{
  gap: 15px;
  padding-top: 15px;

}
}
.Awesome-text{
  padding: 10px;
  background-color: #FFF8DA;
  border: 1px solid #FFCF06;
  border-radius: 12px;
  /* position: absolute; */
  /* margin-top: -500px; */
  width: 300px;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin-top: -50px;
}

.mission-clip3 p{
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 0;
}
.Awesome-text p{
  font-size: 12px;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-bottom: 0;
}

.asw-cards{
  display: flex;
  flex-direction: row;
  /* align-items: start; */
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;

}
.asw-card2-text{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  
  /* height: 30%; */
}
.asw-card{
  box-shadow: 0px 0px 18px 0px #00000012;
  padding: 10px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  flex-direction: row;gap: 15px;
  width: 100%;
  transition: border 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease; /* Transition effect */
  border: 2px solid #ffffff;
}




.asw-card.selected {
  border: 2px solid #4CAF50; /* Green border for selected card */
  background-color: #f0f0f0; /* Change background for selected */
  box-shadow: 0px 0px 15px rgba(0, 128, 0, 0.5); /* Add shadow for better visual */
}

.asw-card img{
  width: 25%;
  border-radius: 10px;

}



.asw-card p{
  font-size: 18px;
  font-weight: 400;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 0;
  text-align: left;
}



.asw-card3{
  box-shadow: 0px 0px 18px 0px #00000012;
  padding: 10px;
  border-radius: 15px;
  display: flex;
  align-items: start;
  flex-direction: column;gap: 15px;
  width: 100%;
  transition: border 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease; /* Transition effect */
  border: 2px solid #ffffff;
}


.asw-card3.selected {
  border: 2px solid #4CAF50; /* Green border for selected card */
  background-color: #f0f0f0; /* Change background for selected */
  box-shadow: 0px 0px 15px rgba(0, 128, 0, 0.5); /* Add shadow for better visual */
}

.asw-card3 img{
  width: 25%;
  border-radius: 10px;

}



.asw-card3 p{
  font-size: 15px;
  font-weight: 400;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 0;
  text-align: left;
}


.newlogo-div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.juice-logo{
  height: 70px;

}
.mission-logo{
  height: 70px;
  border-radius: 50%;
}
.misson-div2{
  height: auto !important;
  min-height: 100vh;
}

.misson-div-right h4{
  font-size: 15px;
  font-weight: 700;
  font-family: "Poppins", sans-serif !important;
  color: #000000;
text-align: center;


}
.mission-banner img{
  width: 100%;
  margin-top: -100px;

}
.mission-fact{
  background-color: #FFF8DA;
  border: 1px solid #FFCF06;
  padding: 15px;
  width: 100%;
  border-radius: 12px;

}
.mission-fact p{
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 15px;
}
.subscribe-btns{
display: flex;
flex-direction: column;
gap: 15px;
width: 100%;

}
.subscribe-btns button{
  width: 100%;
  height: 40px;
  border-radius: 30px;
  border: 1px solid #FFCF06;
  font-size: 15px;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
}
.month-btn{
  background-color: #FFCF06;
  color: white;
}
.month-trail{
  background-color: white;
  color: #FFCF06;
}
.stepper-div{
  background-color: #ffd700;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stepper {
  width: 400px;
  text-align: center;
  padding: 20px;
  background: linear-gradient(to top, #ff4e50, #f9d423); 

}
.step-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 20px; */
  position: relative;
}
.step-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  width: 20px;
}
.circle {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: #ffd700;
}
.circle.active {
  background-color: #EF4136 !important;
  border: 2px solid #EF4136;

}
.step-number {
  font-size: 16px;
  color: white;
}
.line {
  height: 2px;
  width: 100%;
  background-color: white;
  position: absolute;
  top: 12px;
  left: 60%;
  z-index: 0;
}
.line3{
  height: 1px;
  width: 90%;
  background-color: rgb(232, 232, 232);
}
.line.active-line {
  background-color: #ef4136;
}

.step-label {
  margin-top: 10px;

  color: rgb(4, 4, 4);
  font-size: 11px;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
}
.step-label.active-label {
  font-weight: bold;
}
.step-content {
  margin-top: 20px;
  text-align: left;
}
.navigation-buttons button {
  margin: 10px;
  padding: 10px 20px;
  background-color: #EF4136;
  color: white;
  border: none;
  cursor: pointer;
}
button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.slot-banner{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.slot-banner img{
  z-index: 0;

}
.slot-banner-up{
  background-color: #ff4e50;
  margin-top: -40px;
  z-index: 1;
  /* position: absolute; */
 
  width: 250px;
  color: white;
  padding: 20px;
  border-radius: 20px;
}
.slot-banner-up p{
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 0;
}
h3{
  margin-bottom: 0;

}
.slot-banner-up h3{
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 0;
}
.radio-buttons{
  display: flex;
  flex-direction: row;
  /* align-items: center;
  justify-content: center; */
}
.radio-input {
  display: none; /* Hide the default radio button */
}
.radio-label i{
  margin-top: -5px;
}
.radio-label {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px 20px;
  background-color: #ffffff;
  border: 1px solid #EF4136;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s, color 0.3s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
}
.radio-label img{
  height: 30px;
}
.radio-input:checked + .radio-label {
  background-color: #EF4136;
  color: white;
}

.radio-label:hover {
  background-color: #ffd700;
  color: white;
}
.mission-slot-day-eveing h3{
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
  color: rgb(3, 3, 3);
  margin-top: 15px;
}
.mission-date input{
  height: 48px;
  width: 100%;
  background-color: #ffffff;
  border: 2px solid #EF4136;
  border-radius: 5px;
  padding-left: 15px;

}
.mission-numbver input{
  width: 100%;
  border: 1px solid #EEEEEE;
  height: 65px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
}
.missionloginbtn-div{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}
.missionloginbtn{
  width: 45%;
  padding: 15px 20px;
  font-size: 15px;
  font-weight: 500;
  border-radius: 30px;
  font-family: "Poppins", sans-serif !important;
  background-color: #EF4136;
  color: white;
  border: none;
}
.missionloginbtn-back{
  border: 2px solid #EF4136;
  background-color: white !important;
  color: #EF4136;
}
.verify-otp{
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
}
.verify-otp input{
  height: 40px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #EF4136;
padding-left: 20px;
  box-shadow: 15px 20px 45px 0px #E9E9E940;
  
  font-family: "Poppins", sans-serif !important;

}
.otptime{
  text-align: center;  
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 10px;
  margin-top: 10px;

}
.stp2{
  /* min-height: 80vh; */
}
.mission-numbver{

}
.mission-numbver label{
  color: #000000;
  font-size: 17px;
  font-weight: 400;
  font-family: "Poppins", sans-serif !important;
  
}
.address-details h4{
  color: #000000;
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
}
.address-details p{
  color: #000000;
  font-size: 15px;
  font-weight: 400;
  font-family: "Poppins", sans-serif !important;
}
.map{
  width: 100%;
}
.map iframe{
  width: 100%;
  border-radius: 16px;

}
.pin-location{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}
.pin-location h3{
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  font-family: "Poppins", sans-serif !important;
}
.pin-location p{
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  font-family: "Poppins", sans-serif !important;
}
.radio-input2 {
  display: none; /* Hide the default radio button */
}

.radio-label2 {
  width: auto;
  display: inline-block;
  padding: 8px 15px;
  background-color: #ffffff;
  border: 2px solid #EF4136;
  border-radius: 30px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s, color 0.3s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 13px;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
}
.radio-label2 img{
  height: 30px;
}
.radio-input2:checked + .radio-label2 {
  background-color: #EF4136;
  color: white;
}

.radio-label2:hover {
  background-color: #ffd700;
  color: white;
}
.mission-slot-day-eveing h4{
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 0;
}
.upi{
  background-color: white;display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  border-radius: 10px;
}
.upi-div{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}
.upi-div h4{
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 0;

}
.upi h5{
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 0;

}
.upi h6{
  font-size:20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 0;

}
.modala{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modala h3{
  font-size:20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif !important;
  text-align: center;
}
.modala h4{
  font-size:24px;
  font-weight: 700;
  color: #EF4136;
  font-family: "Poppins", sans-serif !important;
}
.modala button{
  height: 59px;
  width: 270px;
  border-radius: 30px;
  border: none;
  color: white;
  background-color: #FFCF06;
  font-size:16px;
  font-weight: 700;
  font-family: "Poppins", sans-serif !important;
}
.continue-button{
  width: 100%;
  padding: 15px 10px;
  margin-bottom: 15px;
  border-radius: 30px;
  border: none;
  color: white;
  background-color: #FFCF06;
  font-size:16px;
  font-weight: 700;
  font-family: "Poppins", sans-serif !important;
}
.asw-card2{
  box-shadow: 0px 0px 18px 0px #00000012;
  padding: 10px;
  border-radius: 15px;
 
  width: 45%;
  transition: border 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease; /* Transition effect */
  border: 2px solid #ffffff;
  text-align: left;
}
.asw-card2 img{
  width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
}
.asw-card2.selected {
  border: 2px solid #4CAF50; /* Green border for selected card */
  background-color: #f0f0f0; /* Change background for selected */
  box-shadow: 0px 0px 15px rgba(0, 128, 0, 0.5); /* Add shadow for better visual */
}
.asw-card2 h4{
  color: black;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
 
}
.asw-card2 p{
  color: black;
  text-align: left;
  font-size: 12px;
  font-weight: 300;
  font-family: "Poppins", sans-serif !important;
 
}
.selected-product-details{
  /* box-shadow: 0px 0px 18px 0px #00000012; */
  width: 100%;
  border-radius: 15px;
  padding: 15px;
  text-align: left;
}

.selected-product-details{

}
.month-trial{
  height: 50px !important;
  border: 1px solid #EF4136 !important;
  background: none;
  transition: border 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease; /* Transition effect */

}
.selectdate{
  width: 100%;
  height: 40px;
  border-radius: 10px;
  padding-left: 15px;
padding-right: 15px;
  border: 1px solid #EF4136;
  font-size: 15px;
  font-weight: 300;
  font-family: "Poppins", sans-serif !important;
}
.duplicate-data{
  background-color: white;
  padding: 15px;
  border-radius: 10px;
}
.duplicate-data h3{
  font-size: 15px;
  font-weight: 300;
  font-family: "Poppins", sans-serif !important;
}
.duplicate-data h3 span{
  font-weight: 500;
}
.step-product-name{
  font-size: 25px;
  font-weight: 700;
  color: #EF4136;
}
@media(max-width:650px){
  .stepper {
    width: 100%;
  }
}
.slidingorange{
  height: 200px;
  margin-top: -50px;
}
.slidingorange2{
 width: 100%;
 margin-top: -70px;

}
.mission-clip4{
  background-color: white;
  padding: 25px 20px;
  border-radius: 10px;
  margin-top: 200px;
  width: 100%;
}
.stp{
  margin-top: -200px;
}

.mission-fact121{
  margin-top: -70px;
}
.selectedpd{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.selectedpd img{
  border-radius: 10px;
}
.selectedpd-div{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.worryfruit{
  width:  100px !important;
}
.new-logo{
width: 200px;
}
.new-misson-div{
  background: linear-gradient(to top, #ff4e50, #f9d423); 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  /* padding-left: 200px;
  padding-right: 200px; */
  padding-top: 30px;
  padding-bottom: 50px;
}
.new500{
  border-radius: 50%;
  /* height: 200px; */
margin-top: -130px;
width: 200px;
}
.white-back{
  background-color: white;
  padding: 20px;
  width: 95%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.white-back2{
  /* height: 60vh; */
}
.white-back h3{
  font-size:13px;
  font-weight: 700;
  font-family: "Poppins", sans-serif !important;
  text-align: center;

  color: #000000;

}
.resend{
  margin-left: 10px;
  background: none;
  border: none;
}
.white-back h3 span{
  font-size: 15px;
  font-weight: 500;
  color: black;
}
.white-back button{
  background-color: #EF4136;
  color: white;
  border: none;
  padding: 10px 50px;
  border-radius: 40px;
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif !important;
}
.mission-heading{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}
.newcenter h3{
  font-size: 35px;
  font-weight: 700;
  font-family: "Poppins", sans-serif !important;
  color: #EF4136;
  text-align: center;
}
.og-col{
  background-color: #FFF8DA;
  border: 2px solid #FFCF06;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  text-align: center;
  margin-top: -60px;
}
.og-col p{
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 0;
}
.mission-btns2{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 100px; */
}
.mission-btns2 button{
  padding: 10px 30px;
  border-radius: 40px;
  font-size: 15px;
  font-weight: 700;
  font-family: "Poppins", sans-serif !important;
}
.everyday{
  width: 70%;
  border-radius: 50%;
}
.bi-wa{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.bi-wa i{
  margin-top: -3px;
}
.juice-div{
  display: flex;
  flex-direction: row ;
  align-items: center;
  gap: 15px;
}
.step-date-div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  padding-left: 15px;
padding-right: 15px;
  border: 1px solid #EF4136;
  font-size: 15px;
  font-weight: 300;
  font-family: "Poppins", sans-serif !important;
}
.step-date{
  border: none;
}
.tab3 button{
  

}
